<template>
  <div class="fill-height slider-cont">
    <v-card class="vertical-slide fill-height">
      <v-card-text class="pa-0">
        <v-row dense class="e-row">
          <v-col cols="6" md="3" class="e-col pa-0 ma-0">
            <v-toolbar class="left-toolbar" flat>
            </v-toolbar>
            <v-tabs v-model="navigationTab" vertical class="left-nav mt-0">
              <template v-for="(item, index) in navigation">
                <v-tab :href="`#${item.slug}`" v-if="hasPermission(item)">
                  <v-icon left>
                    {{item.icon}}
                  </v-icon>
                  {{item.name}}
                  <v-chip class="ml-1 count-chip" v-if="item.name === 'Activity'">
                    <img src="/images/bolt-grey.svg" class="mr-1">
                    <!-- {{commentCount.comment}} -->
                    {{opportunity.comment_count}}
                  </v-chip>
                </v-tab>
              </template>
            </v-tabs>
          </v-col>
          <v-col cols="6" md="9" class="e-col pa-0 ma-0">
            <v-toolbar class="right-toolbar" flat>
              <div class="form-title">Edit Opportunity</div>
            </v-toolbar>
            <div class="slide-right">
              <div class="side-pane">
                <div class="form-wrap px-10">
                  <v-tabs-items v-model="navigationTab">
                    <div class="d-flex my-4">
                      <span class="font-18 line-h-22 text-black-100 font-weight--bold me-2" v-if="opportunity.user && opportunity.user.name">
                        {{ opportunity.user.name }}
                      </span>
                      <span class="font-18 line-h-22 text-black-100 font-weight--bold me-2" v-else>
                        <img :src="`/images/${opportunity.user.source_name.toLowerCase()}.svg`" class="mr-2" v-if="opportunity.user.source_name !== null">
                        {{ opportunity.user.social_handle }}
                      </span>
                      <template v-for="(val, index) in statusTags">
                        <span class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13" :style="
                val.outline
                  ? `border: 1px solid ${val.color}`
                  : `background: ${val.color}`
              " :key="index" v-if="opportunity.user.status === val.id">{{ val.name }}</span>
                      </template>
                      <v-icon class="ml-2" @click="gotoContact(opportunity.user)">mdi-eye-circle-outline</v-icon>
                    </div>
                    <div class="d-flex">
                      <span class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 mr-5" :class="
            opportunity.user.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
          ">{{ opportunity.user.user_type_name }}</span>
                      <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4">
                        <span class="mr-1">
                          <inline-svg src="/images/icons/map.svg" />
                        </span>
                        {{ opportunity.user.branch_name }}
                      </span>
                      <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4" v-if="opportunity.user.email">
                        <span class="mr-1">
                          <inline-svg src="/images/icons/at.svg" />
                        </span>
                        {{ opportunity.user.email }}
                      </span>
                      <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4" v-if="opportunity.user.contact_no">
                        <span class="mr-1">
                          <inline-svg src="/images/icons/mobile.svg" />
                        </span>
                        <span class="mr-1" v-if="opportunity.country_code">({{ opportunity.country_code }})</span>{{ opportunity.user.contact_no }}
                        <v-tooltip content-class="copy-tooltip" :open-on-hover="false" :open-on-click="true" color="#000" text-color="#fff" top>
                          <template v-slot:activator="{ on }">
                            <v-icon @click="copyContent(opportunity.user.contact_no)" v-on="on" size="15" class="ml-2">mdi-content-copy</v-icon>
                          </template>
                          <span>Copied to clipboard</span>
                        </v-tooltip>
                      </span>
                      <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4" v-if="opportunity.user.social_handle !== null">
                        <img :src="`/images/${opportunity.user.source_name.toLowerCase()}.svg`" height="15" class="mr-2" v-if="opportunity.user.source_name !== null">
                        {{ opportunity.user.social_handle }}
                      </span>
                    </div>
                    <v-tab-item value="lead" :eager="true">
                      <v-form ref="leadForm">
                        <div class="tab-content-wrapper">
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt">
                                  Reason for Treatment:
                                </span>
                              </label>
                              <v-combobox v-model="opportunity.reasons" :items="reasons" item-text="name" item-value="id" multiple :search-input.sync="reasonSearch" solo outlined flat chips hide-details="auto" class="este-autocomplete-input">
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        No results matching "<strong>{{ reasonSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                                <template v-slot:selection="data">
                                  <v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
                                    <span class="pr-2" v-if="data.item.name">
                                      {{ data.item.name }}
                                    </span>
                                    <span class="pr-2" v-else>
                                      {{ data.item }}
                                    </span>
                                    <v-icon color="primary" small @click.stop="removeReason(data.item)">
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-combobox>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt">
                                  Symptoms / Condition:
                                </span>
                              </label>
                              <v-autocomplete v-model="opportunity.symptoms" :items="symptoms" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input" @change="symptomChange">
                                <template v-slot:selection="data">
                                  <v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon color="primary" small @click.stop="removeSymptoms(data.item)">
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="6" class="e-col">
                              <label>
                                <span class="label-txt">
                                  Suggested Treatments:
                                </span>
                              </label>
                            </v-col>
                            <v-col cols="12" md="12" class="e-col">
                              <template v-for="treat in suggestedTreatments">
                                <v-chip small class="este-chip status-chip mb-2" text-color="black" @click="suggTreat(treat)">
                                  {{ treat.name | capitalize }}
                                </v-chip>
                              </template>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt">
                                  Service Recommended:
                                </span>
                              </label>
                              <v-autocomplete v-model="opportunity.treatments" :items="treatments" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                                <template v-slot:selection="data">
                                  <v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon color="primary" small @click.stop="removeService(data.item)">
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="4" class="e-col">
                              <label>
                                <span class="label-txt">
                                  Enquiry Date
                                </span>
                                <v-menu v-model="enquiryMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                  <template v-slot:activator="{ on }">
                                    <v-text-field autocomplete="off" :value="dateUkFormat(opportunity.enquiry_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" append-icon="mdil-calendar"></v-text-field>
                                  </template>
                                  <v-date-picker v-model="opportunity.enquiry_date" no-title @input="enquiryMenu = false"></v-date-picker>
                                </v-menu>
                              </label>
                            </v-col>
                            <v-col cols="12" md="4" class="e-col">
                              <label>
                                <span class="label-txt">
                                  Follow Up Date
                                </span>
                                <v-menu v-model="followUpMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                  <template v-slot:activator="{ on }">
                                    <v-text-field @click:clear="clearFollowUpDate" clearable clear-icon="mdi-close-circle-outline" autocomplete="off" :value="dateUkFormat(opportunity.follow_up_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" prepend-inner-icon="mdil-calendar"></v-text-field>
                                  </template>
                                  <v-date-picker v-model="opportunity.follow_up_date" no-title @input="followUpMenu = false"></v-date-picker>
                                </v-menu>
                              </label>
                            </v-col>
                            <v-col cols="12" md="4" class="e-col">
                              <label>
                                <span class="label-txt">Follow Up Time:</span>
                                <v-menu ref="followUpTimeMenu" v-model="followUpTimeMenu" :close-on-content-click="false" :return-value.sync="opportunity.follow_up_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field @click:clear="clearFollowUpTime" clearable clear-icon="mdi-close-circle-outline" outlined flat hide-details="auto" solo class="solo-cust" v-model="opportunity.follow_up_time" prepend-inner-icon="mdil-clock" readonly v-bind="attrs" v-on="on"></v-text-field>
                                  </template>
                                  <v-time-picker :allowed-minutes="allowedStep" no-title ampm-in-title format="24hr" v-if="followUpTimeMenu" v-model="opportunity.follow_up_time" full-width @click:minute="$refs.followUpTimeMenu.save(opportunity.follow_up_time)"></v-time-picker>
                                </v-menu>
                              </label>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt">
                                  Campaigns:
                                </span>
                              </label>
                              <v-autocomplete v-model="opportunity.campaigns" :items="campaigns" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                                <template v-slot:selection="data">
                                  <v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon color="primary" small @click.stop="removeCampaign(data.item)">
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt">
                                  Preferred Branch:
                                </span>
                              </label>
                              <v-select autocomplete="off" :items="branches" :rules="rules.branch_id" item-value="id" item-text="branch" v-model="opportunity.branch_id" solo outlined flat class="solo-cust" hide-details="auto" @change="staffByBranch">
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt">
                                  Source:
                                </span>
                              </label>
                              <v-select autocomplete="off" :rules="rules.source" :items="source" v-model="opportunity.source_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt">
                                  Responsible
                                </span>
                              </label>
                              <v-autocomplete :items="staff" v-model="opportunity.responsible_id" item-text="name" item-value="id" chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                                <template slot="append">
                                  <v-icon>mdi-chevron-down</v-icon>
                                </template>
                                <template v-slot:selection="data">
                                  <v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
                                    <span class="pr-2">
                                      {{ data.item.name }}
                                    </span>
                                    <v-icon color="primary" small @click.stop="removeResponsible(data.item)">
                                      mdi-close
                                    </v-icon>
                                  </v-chip>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="e-row">
                            <v-col cols="12" md="12" class="e-col">
                              <label>
                                <span class="label-txt">
                                  Status
                                </span>
                                <v-select autocomplete="off" :item-disabled="disableStatusOption" :items="statusTags.filter(function(el) {return el.slug.includes('opportunitie')})" item-value="id" item-text="name" v-model="opportunity.status" solo outlined flat class="solo-cust" hide-details="auto">
                                  <template slot="append">
                                    <v-icon>mdi-chevron-down</v-icon>
                                  </template>
                                </v-select>
                              </label>
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item value="activity" :eager="true">
                      <tab-activity-history screenType="opportunity" :itemData="opportunity" :userId="opportunity.user_id" :keyId="opportunity.id"></tab-activity-history>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
                <div class="tab-btn-wrapper">
                  <div class="action-wrap px-10">
                    <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined @click="close" x-large>
                      Cancel
                    </v-btn>
                    <v-btn color="secondary" class="b-blue" depressed @click="updateLead" :loading="loading" rounded x-large>
                      Save Changes
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../store/action-types.js';
import sidePaneMenu from '../../../mixins/sidePaneMenu.js';
import globalFn from '../../../mixins/globalFn';
import status from '../../../mixins/status.js';
import TabActivity from '../../../components/TabItems/TabActivity';
import TabActivityHistory from '../../../components/TabItems/TabActivityHistory';
import TabInsight from '../../../components/TabItems/TabInsight';
import TabCallback from '../../../components/TabItems/TabCallback';
import TabAppointment from '../../../components/TabItems/TabAppointment';
import editLead from './editLead.js';
export default {
  mixins: [globalFn, status, editLead, sidePaneMenu],
  components: {
    TabActivity,
    TabActivityHistory,
    TabInsight,
    TabCallback,
    TabAppointment
  },
  data: () => ({}),
  methods: {},
  mounted() {
    this.paneTab = 'lead'
    this.leadUnit = true
  },
  methods: {

  }
};

</script>
