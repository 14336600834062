<template>
	<div class="content-wrapper">
		<v-layout>
			<v-flex>
				<v-row>
					<template v-for="(item, index) in widgets.filter(function(el) {return el.module.includes('opportunities')})">
						<v-col>
							<div class="widget" :class="item.active ? 'selected' :''" @click="widgetFilter(item)">
								<span class="active" v-if="item.active">
									<img class="mt-2 mr-2" src="/images/check-circle.svg" width="16" height="16">
								</span>
								<div class="content">
									<div class="mb-5">
										<span class="m-0">
											<inline-svg width="34" height="34" :src="item.svg" />
										</span>
									</div>
									<p class="number">{{item.count}}</p>
									<p class="text">{{item.name}}</p>
								</div>
								<span class="bottom-arrow" v-if="item.active"></span>
							</div>
						</v-col>
					</template>
				</v-row>
				<v-card flat class="pa-0 mt-5">
					<v-card-text class="pa-0 pt-3">
						<v-skeleton-loader class="widget-card" v-show="loading" :loading="loading" type="table-row-divider@6"></v-skeleton-loader>
						<v-data-table :item-class="itemRowBackground" :fixed-header="true" v-show="!loading" class="results-table" item-key="id" :headers="headers" :items="items" :options.sync="options" sort-by="updated_at" :items-per-page="itemsPerPage" :footer-props="footerProps" :server-items-length="totalCount">
							<template v-slot:item.name="{ item }">
								<div class="d-flex align-center flex-row position-relative mr-4 mb-2 cursor">
									<span class="font-14 line-h-17 font-weight--bold text-black-100 ml-2" v-if="item.user.name" @click="$router.push({path: `/view-contact/${item.user.id}`})">
										{{ item.user.name }}
									</span>
									<span class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip bg-gray-100 border" v-if="!item.user.name && !item.user.social_handle">Not Supplied</span>
									<span class="font-14 line-h-17 font-weight--bold text-black-100 ml-2 d-flex align-center justify-center" v-if="item.user.name === null" @click="$router.push({path: `/view-contact/${item.user.id}`})">
										<img :src="`/images/${item.user.source_name.toLowerCase()}.svg`" class="mr-1" v-if="item.user.source_name !== null">
										{{ item.user.social_handle }}
									</span>
									<span class="badge--chip small ml-2 bg-gold-100 font-weight--bold text-black-300 font-11 line-h-13 cursor" v-if="item.opportunity_count > 1" @click="filterOpportuntiyCount(item)">
										{{ item.opportunity_count }}
									</span>
									<span class="position-absolute small mt-n5 ml-n1 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                item.user.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
										{{ item.user.user_type_name === "Lead" ? "L" : "C" }}
									</span>
								</div>
								<div class="d-flex align-center mt-2 ml-2">
									<span class="font-13 line-h-16 text-black-300 font-weight--semibold mr-2" v-if="item.user.contact_no">
										<span v-if="item.user.country_code">({{ item.user.country_code }})</span> {{ item.user.contact_no }}
										<v-tooltip content-class="copy-tooltip" :open-on-hover="false" :open-on-click="true" color="#000" text-color="#fff" top>
											<template v-slot:activator="{ on }">
												<v-icon @click="copyContent(item.user.contact_no)" v-on="on" size="15">mdi-content-copy</v-icon>
											</template>
											<span>Copied to clipboard</span>
										</v-tooltip>
									</span>
									<span class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border mr-2" v-if="item.user.language">
										<v-icon size="12" class="mr-1">mdi-translate</v-icon>
										{{ item.user.language }}
									</span>
									<span class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border" v-if="item.branch_name">{{ item.branch_name }}</span>
								</div>
							</template>
							<template v-slot:item.enquiry="{ item }">
								<div class="d-flex flex-column">
									<span class="font-13 line-h-16 font-weight--regular text-black-300 mb-1">
										{{item.enquiry_date | moment("DD/MM/YY")}}
									</span>
									<span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300" v-if="item.follow_up_date">
										{{item.follow_up_date | moment("DD/MM/YY")}} {{item.follow_up_time}}
										<img src="/images/icons/duotune/arrows/arr050.svg" class="text-black-100 ma-0 ml-1" width="18" height="18" />
									</span>
								</div>
							</template>
							<template v-slot:item.responsible="{ item }">
								<v-tooltip content-class="este-tooltip" color="#000" text-color="#fff" top v-if="item.responsible !== null">
									<template v-slot:activator="{ on, attrs }">
										<span v-bind="attrs" v-on="on" class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold cursor">
											{{ getInitials(item.responsible.name) }}
										</span>
									</template>
									<span v-if="item.responsible !== null">{{item.responsible.name}}</span>
								</v-tooltip>
							</template>
							<template v-slot:item.source="{ item }">
								<span v-if="item.source_name" class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold">
									{{ item.source_name }}
								</span>
								<div class="mt-2" v-if="item.campaigns.length > 0">
									<template v-for="(campaign,index) in item.campaigns.slice(0,1)">
										<span class="badge--chip font-weight--semibold white--text  font-11 line-h-13" :style="`background: ${campaign.color}`" :key="index"> {{ campaign.chip | capitalize }}</span>
									</template>
									<span class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold" v-if="item.campaigns.length > 1">+{{item.campaigns.length -1}}</span>
								</div>
							</template>
							<template v-slot:item.updated_at="{ item }">
								<div class="d-flex flex-column width-100">
									<span class="font-13 line-h-16 font-weight--semibold text-black-300 mb-1">
										{{item.updated_at}}
									</span>
									<div class="d-flex">
										<QuickComment :opportunity="item" class="mr-2" />
										<CallbackNote :opportunity="item" />
									</div>
								</div>
							</template>
							<template v-slot:item.treatments="{ item }">
								<span v-if="item.treatment_category" class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold mr-2">
											{{ item.treatment_category }}
										</span>

								<template v-if="item.treatments.length > 0">
									<template v-for="(val, index) in item.treatments.slice(0,1)">
										<span :key="index" class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold mr-2">
											{{ val.name }}
										</span>
									</template>
									<span class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold" v-if="item.treatments.length > 1">+{{item.treatments.length -1}}</span>
								</template>
							</template>
							<template v-slot:item.status="{ item }">
								<div class="d-flex flex-column align-start justify-start">
									<template v-for="(val, index) in statusTags">
										<span class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13" :style="
                val.outline
                  ? `border: 1px solid ${val.color}`
                  : `background: ${val.color}`
              " :key="index" v-if="item.status === val.id">{{ val.name }}</span>
									</template>
									<div class="mt-2" v-if="item.branch_name">
										<span class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border">{{ item.branch_name }}</span>
									</div>
								</div>
							</template>
							<template v-slot:item.action="{ item }">
								<div class="d-flex align-center justify-end">
									<v-btn v-if="item.is_urgent" :elevation="0" class="bg-red-100 btn-action mr-2" @click="flagStatus(item, 0)">
										<inline-svg class="white--text" width="16" height="16" src="/images/icons/duotune/maps/map001.svg" />
									</v-btn>
									<v-btn v-if="!item.is_urgent" :elevation="0" class="bg-gray-100 btn-action mr-2" @click="flagStatus(item, 1)">
										<inline-svg class="text-black-100" width="16" height="16" src="/images/icons/duotune/maps/map001.svg" />
									</v-btn>
									<v-btn :elevation="0" class="bg-gray-100 btn-action" @click="editItem(item)">
										<img height="16" src="/images/icons/duotune/general/gen055.svg" />
									</v-btn>
								</div>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-flex>
		</v-layout>
		<v-dialog v-model="modalDialog" max-width="490">
			<v-card class="delete-alert">
				<v-card-title>
					Confirm
					<a class="float-right pa-6" @click="modalDialog = false"><img src="/images/close.svg" alt="Close" /></a>
				</v-card-title>
				<v-card-text>
					{{message}}
				</v-card-text>
				<v-card-actions class="d-flex justify-center align-center">
					<div class="delete-wrapper">
						<v-btn class="cancel-btn btn-gap" depressed rounded outlined @click="modalDialog = false" color="dark" x-large>
							Cancel
						</v-btn>
						<v-btn class="delete-btn" :color="(actionText === 'Archive') ? 'red' : 'secondary'" depressed @click="handleFunction(action)" rounded x-large>
							{{actionText}}
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../store/action-types.js';
import globalFn from '../../../mixins/globalFn';
import sliderFn from '../../../mixins/sliderFn';
import status from '../../../mixins/status';
import OpportunitiesWidgets from '../../../mixins/OpportunitiesWidgets';
import eventBus from '../../../eventBus.js';
import QuickComment from './QuickComment.vue'
import CallbackNote from './CallbackNote.vue'
export default {
	name: "Opportunities",
	mixins: [globalFn, sliderFn, status, OpportunitiesWidgets],
	components: {
		QuickComment,
		CallbackNote
	},
	data() {
		return {
			user: JSON.parse(localStorage.getItem('user')),
			csvLoading: false,
			jsonData: [],
			loading: false,
			showSkeleton: false,
			modalDialog: false,
			headers: [{ text: 'Name', value: 'name', sortable: false },
				{ text: 'Source', value: 'source', sortable: false },
				{ text: 'Treatments', value: 'treatments', sortable: false },
				{ text: 'Staff', value: 'responsible', sortable: false },
				{ text: 'Enquiry', value: 'enquiry', sortable: false, },
				{ text: 'Updated', value: 'updated_at', sortable: false, },
				{ text: 'Status', value: 'status', sortable: false },
				{ text: 'Action', value: 'action', sortable: false, align: 'end' }
			],
			search: "",
			isTyping: false,
			page: 1,
			totalCount: 0,
			itemsPerPage: 20,
			options: {},
			sortOrder: 'desc',
			sortBy: 'updated_at',
			items: [],
			footerProps: {
				itemsPerPageOptions: [10, 15, 20, 25, 50],
				showFirstLastPage: true,
				showCurrentPage: true,
			},
			selectedItem: '',
			payload: {
				search: '',
				attributes: 'user,campaigns,treatments,responsible',
				filter: {
					enquiry_from: '',
					enquiry_to: '',
					created_at_from: '',
					created_at_to: '',
					followup_from: '',
					followup_from_time: '',
					followup_to: '',
					followup_to_time: '',
					status: [],
					branch: [],
					source: [],
					assigned: [],
					assigned_to: 0,
					source_blank: 0,
					branch_blank: 0,
				},
				widget_filter: '',
				opps_count: false,
				user_id: '',
				per_page: 20,
				page: 1,
				sort_by: 'updated_at',
				sort_order: 'desc'
			},
			message: '',
			action: '',
			actionText: '',
			enrolleUserValue: 0,
			flagStatusValue: 0,
		}
	},
	watch: {
		options: {
			handler($event) {
				const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
				if ($event.sortBy[0]) {
					this.sortBy = $event.sortBy[0];
					this.sortOrder = sortOrder;
					this.paginate($event);
				}
			},
			deep: true
		},
	},
	computed: {
		csvFileName() {
			const date = new Date().toLocaleString().replace(',', '');
			return `lead_csv_${date}`
		},
	},
	mounted() {
		this.$root.$on('opportunitiesTable', this.refreshScreen)
		this.$root.$on('searchOpportunities', this.searchOpportunities)
	},
	created() {
		window.Echo.channel("new-lead").listen(".lead-created", e => {
			this.options.page = 1;
			let res = []
			res.type = 'success'
			res.treatments = []
			res.treatments = e.data.treatments
			let name = (e.data.name) ? e.data.name : `@ ${e.data.social_handle}`
			res.message = (e.data.treatments !== null && e.data.treatments.length > 0) ? `New Lead Added: ${name} for` : `New Lead Added: ${name}`
			if (this.user.user_type_name === 'Admin') {
				this.$store.dispatch('snackbar/SHOW', res)
				this.readTheDataFromApi()
			} else if (this.user.permission_branches.some(el => el.id === e.data.branch_id) || this.user.branch_id === e.data.branch_id) {
				this.$store.dispatch('snackbar/SHOW', res)
				this.readTheDataFromApi()
			}
		});
	},
	methods: {

		getClockClass(item){
			switch (item.preferred_time) {
            case 'AM':
                return "clock--morning";
            case 'EVE':
                return "clock--evening";
            case 'PM':
                return "clock--afternoon";
            default:
                return '';
        }
		},

		itemRowBackground(item) {
			return (item.is_urgent) ? 'bg-red-200' : '';
		},
		async searchOpportunities(data) {
			this.options.page = 1;
			this.items = []
			if (data.widget_filter) {
				this.widgets.forEach(function(el, index) {
					el.active = false
				});
				this.payload.widget_filter = ''
			}
			this.payload.search = data.search
			this.payload.filter.enquiry_from = data.enquiry_from
			this.payload.filter.enquiry_to = data.enquiry_to
			this.payload.filter.created_at_from = data.created_at_from
			this.payload.filter.created_at_to = data.created_at_to
			this.payload.filter.followup_from = data.followup_from
			this.payload.filter.followup_from_time = data.followup_from_time
			this.payload.filter.followup_to = data.followup_to
			this.payload.filter.followup_to_time = data.followup_to_time
			this.payload.filter.appointment_from = data.appointment_from
			this.payload.filter.appointment_to = data.appointment_to
			this.payload.filter.satisfaction = data.satisfaction
			if (this.payload.widget_filter === 'urgent') {
				this.payload.filter.status = []
			} else {
				this.payload.filter.status = data.status
			}
			this.payload.filter.branch = data.branch
			this.payload.filter.source = data.source
			this.payload.filter.assigned = data.assigned
			this.payload.filter.assigned_to = data.assigned_to
			this.payload.filter.source_blank = data.source_blank
			this.payload.filter.branch_blank = data.branch_blank
			this.payload.opps_count = false
			await this.refreshScreen()

		},
		async refreshScreen() {
			await this.loadCount()
			await this.readTheDataFromApi()
		},
		async widgetFilter(item) {
			item.active = !item.active
			this.widgets.forEach(function(el, index) {
				if (el.slug !== item.slug) {
					el.active = false
				}
			});
			this.items = []
			this.payload.widget_filter = (item.active) ? item.slug : ''
			this.options.page = 1;
			await this.refreshScreen()
		},
		async filterOpportuntiyCount(item) {
			this.payload.opps_count = !this.payload.opps_count
			this.payload.user_id = item.user_id
			this.options.page = 1;
			await this.refreshScreen()
		},
		async paginate(e) {
			this.payload.per_page = e.itemsPerPage;
			this.payload.page = e.page;
			await this.refreshScreen()
		},
		async readTheDataFromApi() {
			this.loading = true;
			await this.$store.dispatch('opportunities/GET', this.payload).then(res => {
				this.items = res.data.data
				this.totalCount = res.data.total
			})
			this.loading = false;

			if (this.payload.search && this.items.length === 0) {
				this.$root.$emit('showCreateNew', true)
			} else {
				this.$root.$emit('showCreateNew', false)
			}
		},
		addComplaint(item) {
			if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') {
				this.openDrawer("add", "1100", "complaints/addPane");
			} else {
				this.openDrawer("add", "750", "complaints/addTab");
			}
			localStorage.setItem('complaintClientID', item.id)
		},
		async editItem(item) {

			await this.$store.dispatch('opportunities/SHOW', { id: item.id }).then(res => {
				if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') {
					this.openDrawer("add", "1100", "opportunities/editPane");
				} else {
					this.openDrawer("add", "750", "opportunities/editTab");
				}
			})

		},
		handleFunction(funName) {
			this[funName]()
		},
		async flagStatus(item, val) {
			this.selectedItem = item
			this.flagStatusValue = val
			this.modalDialog = true
			this.message = `Please confirm you want to ${(val ? 'flag' : 'unflag')} this opportunity?`
			this.actionText = "Confirm"
			this.action = "flagOpportunity"
		},
		async flagOpportunity() {
			await this.$store.dispatch(`opportunities/UPDATE`, { id: this.selectedItem.id, is_urgent: this.flagStatusValue }).then(res => {
				if (res.result === 'success') {
					this.modalDialog = false
					this.refreshScreen()
				} else if (res.result === 'fail') {
					res.type = 'error'
					this.$store.dispatch('snackbar/SHOW', res)
				}
			}).catch(err => {
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		},

		async enableAppUser(item, value) {
			this.selectedItem = item
			this.enrolleUserValue = value
			this.modalDialog = true
			if (value) {
				this.message = "Please confirm you want to enable app user?"
				this.actionText = "Enable"
				this.action = "enrollUser"

			} else {
				this.message = "Please confirm you want to disable app user?"
				this.actionText = "Disable"
				this.action = "enrollUser"
			}
		},

		async enrollUser() {
			await this.$store.dispatch(`opportunities/ENROLL_USER`, { user_id: this.selectedItem.id, enable_app_user: this.enrolleUserValue }).then(res => {
				if (res.result === 'success') {
					this.modalDialog = false
					this.refreshScreen()
				} else if (res.result === 'fail') {
					res.type = 'error'
					this.$store.dispatch('snackbar/SHOW', res)
				}
			}).catch(err => {
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		}
	}
};

</script>
