<template>
  <v-card flat>
    <v-toolbar flat height="90px" class="tab__toolbar" :class="responsiveClass">
      <v-toolbar-title>
        <div class="form-title">Add Opportunity</div>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="navigationTab" :vertical="vertical" class="este__tabs" :class="responsiveClass">
      <v-tabs-slider class="cust-tabs-slider" v-if="!vertical"></v-tabs-slider>
      <template v-for="(item, index) in navigation">
        <v-tab :href="`#${item.slug}`">
          <v-icon left>
            {{item.icon}}
          </v-icon>
          {{item.name}}
        </v-tab>
      </template>
    </v-tabs>
    <div class="tab__content__wrapper">
      <v-tabs-items v-model="navigationTab">
        <v-tab-item value="lead" :eager="true">
          <div class="content__text">
            <div class="d-flex my-4">
              <span class="font-18 line-h-22 text-black-100 font-weight--bold me-2" v-if="contact && contact.name">
                {{ contact.name }}
              </span>
              <span class="font-18 line-h-22 text-black-100 font-weight--bold me-2" v-else>
                <img :src="`/images/${contact.source_name.toLowerCase()}.svg`" class="mr-2" v-if="contact.source_name !== null">
                {{ contact.social_handle }}
              </span>
              <template v-for="(val, index) in statusTags">
                <span class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13" :style="
                val.outline
                  ? `border: 1px solid ${val.color}`
                  : `background: ${val.color}`
              " :key="index" v-if="contact.status === val.id">{{ val.name }}</span>
              </template>
            </div>
            <div class="d-flex mb-4">
              <span class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13 mr-5" :class="
            contact.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
          ">{{ contact.user_type_name }}</span>
              <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4">
                <span class="mr-1">
                  <inline-svg src="/images/icons/map.svg" />
                </span>
                {{ contact.branch_name }}
              </span>
              <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4" v-if="contact.email">
                <span class="mr-1">
                  <inline-svg src="/images/icons/at.svg" />
                </span>
                {{ contact.email }}
              </span>
              <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4" v-if="contact.contact_no">
                <span class="mr-1">
                  <inline-svg src="/images/icons/mobile.svg" />
                </span>
                <span class="mr-1" v-if="contact.country_code">({{ contact.country_code }})</span>{{ contact.contact_no }}
                <v-tooltip content-class="copy-tooltip" :open-on-hover="false" :open-on-click="true" color="#000" text-color="#fff" top>
                  <template v-slot:activator="{ on }">
                    <v-icon @click="copyContent(contact.contact_no)" v-on="on" size="15" class="ml-2">mdi-content-copy</v-icon>
                  </template>
                  <span>Copied to clipboard</span>
                </v-tooltip>
              </span>
              <span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300 mr-4" v-if="contact.social_handle !== null">
                <img :src="`/images/${contact.source_name.toLowerCase()}.svg`" height="15" class="mr-2" v-if="contact.source_name !== null">
                {{ contact.social_handle }}
              </span>
            </div>
            <v-form ref="form">
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt">
                      Reason for Treatment:
                    </span>
                  </label>
                  <v-combobox v-model="form.reasons" :items="reasons" item-text="name" item-value="id" multiple :search-input.sync="reasonSearch" solo outlined flat chips hide-details="auto" class="este-autocomplete-input">
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ reasonSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                      <v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
                        <span class="pr-2" v-if="data.item.name">
                          {{ data.item.name }}
                        </span>
                        <span class="pr-2" v-else>
                          {{ data.item }}
                        </span>
                        <v-icon color="primary" small @click.stop="removeReason(data.item)">
                          mdi-close
                        </v-icon>
                      </v-chip>
                    </template>
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt">
                      Symptoms / Condition:
                    </span>
                  </label>
                  <v-autocomplete v-model="form.symptoms" :items="symptoms" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input" @change="symptomChange">
                    <template v-slot:selection="data">
                      <v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
                        <span class="pr-2">
                          {{ data.item.name }}
                        </span>
                        <v-icon color="primary" small @click.stop="removeSymptoms(data.item)">
                          mdi-close
                        </v-icon>
                      </v-chip>
                    </template>
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt">
                      Suggested Treatments:
                    </span>
                  </label>
                </v-col>
                <v-col cols="12" md="12" class="e-col">
                  <template v-for="treat in suggestedTreatments">
                    <v-chip small class="este-chip status-chip mb-2" text-color="black" @click="suggTreat(treat)">
                      {{ treat.name | capitalize }}
                    </v-chip>
                  </template>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt">
                      Service Recommended:
                    </span>
                  </label>
                  <v-autocomplete v-model="form.service_recommended" :items="treatments" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                    <template v-slot:selection="data">
                      <v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
                        <span class="pr-2">
                          {{ data.item.name }}
                        </span>
                        <v-icon color="primary" small @click.stop="removeService(data.item)">
                          mdi-close
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="4" class="e-col">
                  <label>
                    <span class="label-txt">
                      Enquiry Date
                    </span>
                    <v-menu v-model="enquiryMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field autocomplete="off" prepend-inner-icon="mdil-calendar" clearable clear-icon="mdi-close-circle-outline" :value="dateUkFormat(form.enquiry_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust"></v-text-field>
                      </template>
                      <v-date-picker v-model="form.enquiry_date" no-title @input="enquiryMenu = false"></v-date-picker>
                    </v-menu>
                  </label>
                </v-col>
                <v-col cols="12" md="4" class="e-col">
                  <label>
                    <span class="label-txt">
                      Follow Up Date
                    </span>
                    <v-menu v-model="followUpMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field autocomplete="off" prepend-inner-icon="mdil-calendar" clearable clear-icon="mdi-close-circle-outline" :value="dateUkFormat(form.follow_up_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust"></v-text-field>
                      </template>
                      <v-date-picker v-model="form.follow_up_date" no-title @input="followUpMenu = false"></v-date-picker>
                    </v-menu>
                  </label>
                </v-col>
                <v-col cols="12" md="4" class="e-col">
                  <label>
                    <span class="label-txt">Follow Up Time:</span>
                    <v-menu ref="followUpTimeMenu" v-model="followUpTimeMenu" :close-on-content-click="false" :return-value.sync="form.follow_up_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined flat hide-details="auto" solo class="solo-cust" v-model="form.follow_up_time" append-icon="mdil-clock" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-time-picker :allowed-minutes="allowedStep" no-title ampm-in-title format="24hr" v-if="followUpTimeMenu" v-model="form.follow_up_time" full-width @click:minute="$refs.followUpTimeMenu.save(form.follow_up_time)"></v-time-picker>
                    </v-menu>
                  </label>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt">
                      Campaigns:
                    </span>
                  </label>
                  <v-autocomplete v-model="form.campaign_ids" :items="campaigns" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                    <template v-slot:selection="data">
                      <v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
                        <span class="pr-2">
                          {{ data.item.name }}
                        </span>
                        <v-icon color="primary" small @click.stop="removeCampaign(data.item)">
                          mdi-close
                        </v-icon>
                      </v-chip>
                    </template>
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt">
                      Source:
                    </span>
                  </label>
                  <v-select autocomplete="off" :rules="rules.source" :items="source" v-model="form.opp_source_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt">
                      Responsible
                    </span>
                    <v-select autocomplete="off" :items="staff" v-model="form.opp_responsible_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </label>
                </v-col>
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt">
                      Preferred Branch:
                    </span>
                  </label>
                  <v-select autocomplete="off" :items="branches" :rules="rules.opp_branch_id" item-value="id" item-text="branch" v-model="form.opp_branch_id" solo outlined flat class="solo-cust" hide-details="auto">
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn color="secondary" class="b-blue" depressed :disabled="disabled" @click="create" rounded x-large>
          Save Changes
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
import globalFn from '../../../mixins/globalFn';
import status from '../../../mixins/status';
export default {
  mixins: [globalFn, status],
  data: () => ({
    navigationTab: null,

    enquiryMenu: false,
    followUpTimeMenu: false,
    followUpMenu: false,
    disabled: false,
    navigation: [{
      name: 'Opportunity Details',
      icon: 'mdil-star',
      slug: 'lead',
      module: 'lead',
      form: true
    }],
    reasonSearch: null,
    suggestedTreatments: [],
    rules: {
      name: [
        v => !!v || 'Please enter name'
      ],
      email: [
        v => !!v || "Please enter email address",
        v => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com"
      ],
      branch_id: [
        v => !!v || 'Please select branch'
      ],
      status: [],
      comment: [
        v => !!v || 'Please enter comment'
      ],
      source: [
        v => !!v || 'Please select source'
      ],
    },
    staff: [],
    form: {
      user_id: null,
      enquiry_date: new Date().toISOString().slice(0, 10),
      follow_up_date: new Date().toISOString().slice(0, 10),
      follow_up_time: '',
      opp_responsible_id: '',
      opp_branch_id: '',
      opp_source_id: '',
      campaign_ids: [],
      reasons: [],
      symptoms: [],
      service_recommended: [],
      status: null,
    }
  }),
  computed: {
    responsiveClass() {
      return (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') ? 'lg' : 'md'
    },
    vertical() {
      return (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') ? true : false
    },
    contact() {
      return this.$store.state.contacts.item
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list
      }
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList
      }
    },
    reasons() {
      if (this.$store.state.reasons.allList) {
        return this.$store.state.reasons.allList
      }
    },
    symptoms() {
      if (this.$store.state.symptoms.allList) {
        return this.$store.state.symptoms.allList
      }
    },
    campaigns() {
      if (this.$store.state.campaign.all) {
        return this.$store.state.campaign.all
      }
    },
  },
  created() {
    this.$store.dispatch('branches/GET_ALL_BRANCH', '')
    this.$store.dispatch('source/GET_SOURCE', '')
    this.$store.dispatch('reasons/GET_ALL_REASON', '')
    this.$store.dispatch('campaign/LIST', '')
    this.$store.dispatch('symptoms/GET_ALL_SYMPTOM', '')
    this.$store.dispatch('treatments/GET_ALL_TREATMENT', '')
  },
  mounted() {
    this.getStaffList()
  },
  methods: {
    async suggTreat(item) {
      let exists = this.form.service_recommended.some(el => el.id === item.id) ? true : this.form.service_recommended.some(el => el === item.id) ? true : false;
      if (!exists) {
        this.form.service_recommended.push(item.id)
      } else {
        const index = this.form.service_recommended.findIndex(x => x.id === item.id)
        if (index >= 0) {
          this.form.service_recommended.splice(index, 1)
        } else {
          const i = this.form.service_recommended.findIndex(x => x === item.id)
          if (i >= 0) this.form.service_recommended.splice(i, 1)
        }
      }
    },
    async removeService(item) {
      const index = this.form.service_recommended.findIndex(x => x.id === item.id)
      if (index >= 0) {
        this.form.service_recommended.splice(index, 1)
      } else {
        const i = this.form.service_recommended.findIndex(x => x === item.id)
        if (i >= 0) this.form.service_recommended.splice(i, 1)
      }
    },
    async removeSymptoms(item) {
      const index = this.form.symptoms.findIndex(x => x.id === item.id)
      if (index >= 0) {
        this.form.symptoms.splice(index, 1)
      } else {
        const i = this.form.symptoms.findIndex(x => x === item.id)
        if (i >= 0) this.form.symptoms.splice(i, 1)
      }
      await this.symptomChange()
    },
    async removeCampaign(item) {
      const index = this.form.campaign_ids.findIndex(x => x.id === item.id)
      if (index >= 0) {
        this.form.campaign_ids.splice(index, 1)
      } else {
        const i = this.form.campaign_ids.findIndex(x => x === item.id)
        if (i >= 0) this.form.campaign_ids.splice(i, 1)
      }
    },
    async removeReason(item) {
      if (item.id) {
        const index = this.form.reasons.findIndex(x => x.id === item.id)
        if (index >= 0) {
          this.form.reasons.splice(index, 1)
        } else {
          const i = this.form.reasons.findIndex(x => x === item.id)
          if (i >= 0) this.form.reasons.splice(i, 1)
        }
      } else {
        const i = this.form.reasons.findIndex(x => x === item)
        if (i >= 0) this.form.reasons.splice(i, 1)
      }
    },
    async symptomChange() {
      const ids = this.form.symptoms
      await this.$store.dispatch('symptoms/GET_SUGGESTED_TREATMENTS', { 'symptoms': ids }).then(response => {
        if (response.result === 'success') {
          this.suggestedTreatments = response.data
        } else if (response.result === 'fail') {
          response.type = 'error'
          this.$store.dispatch('snackbar/SHOW', response)
        }
      }).catch(err => {
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    },
    async create() {
      if (this.$refs.form.validate()) {

        this.disabled = true
        await this.$store.dispatch('opportunities/CREATE', this.form).then(response => {
          if (response.result === 'success') {
            this.disabled = false
            this.loadData()
          } else if (response.result === 'fail') {
            response.type = 'error'
            this.disabled = false
            this.$store.dispatch('snackbar/SHOW', response)
          }
        }).catch(err => {
          this.disabled = false
          err.type = 'error'
          this.$store.dispatch('snackbar/SHOW', err)
        });
      }
    },
    loadData() {
      this.$store.commit("rightSlider/RESET_SLIDER");
      this.$root.$emit("refreshOppsWidget", "");
    },

    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async getStaffList() {
      this.form.user_id = this.contact.id
      this.form.opp_branch_id = this.contact.branch_id
      this.form.opp_responsible_id = this.contact.responsible_id
      this.staff = []
      await this.$store.dispatch('staff/GET_STAFF_LIST', { data: { branch: this.contact.branch_id }, params: '' }).then(response => {
        if (response.result === 'success') {
          this.staff = response.data
        } else if (response.result === 'fail') {
          response.type = 'error'
          this.$store.dispatch('snackbar/SHOW', response)
        }
      }).catch(err => {
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    },

  }
}

</script>
